// Your variable overrides

// Bootstrap and its default variables
@import '../../node_modules/bootstrap/scss/bootstrap.scss';
@import 'mire_v2.scss';

:root {
    --bs-font-sans-serif: 'interface', sans-serif;
}

body {
    background: $black;
}

#logo {
    display: block;
    text-indent: -9999px;
    background: url(../images/SVG/logo-dark.svg) center center no-repeat;
    background-size: contain;
    width: 140px;
    height: 140px;
    position: absolute;
    top: 40px;
    left: 40px;
    z-index: 2;
}

#forms {

    background: url(../images/1x/Fond_Landing_groupe.jpg) center center no-repeat;
    background-size: cover;

    @include media-breakpoint-down(md){
        background: #000 url(../images/1x/fond_responsive.jpg) top center no-repeat;
        background-size: 100% auto;
    }

    @media screen and (min-width: 1200px) and (max-height: 980px){
        max-height: 86vh;

        #contact {
            margin-top: 40px;
            &:before {
                margin-top: 50px;
            }
        }

    }
}

#contact {
    
    //min-height: 300px;
    max-width: 424px;
    background-color: rgba(75,91,102,.6);
    border-radius: 10px;

    padding-left: 45px;
    padding-right: 45px;
    margin-top: 130px;
    margin-bottom: 100px;
    margin-left: auto;
    margin-right: auto;

    &:before {
        content: "";
        display: block;
        width: 513px;
        padding-top: 38.79%;
        background: url(../images/SVG/bien-etre.svg) center center no-repeat;
        background-size: contain;
        @include media-breakpoint-down(lg){
            width: 80%;
            margin: 20px auto;
        }
    }

    @include media-breakpoint-down(md){
        margin-top: 200px;
        max-width: calc(100% - 40px);
    }

    @include media-breakpoint-up(lg){
        margin-left: 228px;
        position: relative;
        &:before {
            @media screen and (min-width: 1200px){
                position: absolute;
                top: 0;
                right: 0;
                transform: translate(100%, 100%);
            }

            // @media screen and (min-width: 1280px){
            //     transform: translate(calc(100% + 228px), 100%);
            // }
        
            @media screen and (min-width: 1440px){
                transform: translate(calc(100% + 105px), 100%);
            }

        }
    }

    h2 {
        font-size: 1.31rem;
        font-weight: bold;
        color: #fff;
        text-align: right;
        text-transform: uppercase;
        margin-top: 20px;
        letter-spacing: 1px;
        span {
            font-weight: 300;
        }
    }

    form {

        max-width: 500px;
        margin: 0 auto;

        label {
            color: $white;
            text-transform: uppercase;
            font-weight: normal;
            font-size: .85rem;
        }

        input[type="text"],
        input[type="email"],
        textarea {
            color: #494949;
            border: none;
            border-radius: 10px;
            font-size: .85rem;
            &::placeholder {
                color: #9c9b9b;
                text-transform: lowercase;
                font-style: italic;
            }
        }

        textarea {
            min-height: 150px;
        }

        #_condition, .form-check {
            margin: 10px 0;
            label {
                font-family: lato, sans-serif;
                font-size: .6875rem;
                text-transform: none;
                text-align: left;
                font-weight: normal;
                line-height: .9;
                color: #fff;
            }
        }
        .form-group {
            margin-bottom: 5px;
        }
        input[type="submit"]{
            background: $black;
            color: #fff;
            border: none;
            box-shadow: none;
            outline: none;
            text-transform: uppercase;
            text-decoration: none;
            font-size: .9375rem;
            font-weight: normal;
            text-align: center;
            padding: 8px 30px;
            margin-left: auto;
            margin-right: auto;
            border-radius: 15px;
            width: 180px;
            display: block;
            &:disabled {
                opacity: .3;
                cursor: not-allowed;
            }
        }
    }

    .alert {
        max-width: 80%;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
        .alert-heading, .close {
            display: none;
        }
        &.alert-success {
            font-weight: bold;
            font-size: 1rem;
            font-family: sans-serif;
        }
    }

    p.success {
        margin-top: 150px;
        margin-bottom: 150px;
    }

}

#logos {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 50px;
    width: fit-content;

    @media screen and (min-width: 1280px){
        transform: translate(calc(100% + 228px), calc(-200% - 35px));
        margin-left: 0;
        margin-bottom: 0;
    }

    @media screen and (min-width: 1440px){
        transform: translate(calc(100% + 228px + 105px), calc(-200% - 35px));
        margin-left: 0;
        margin-bottom: 0; 
    }

    a, span.logo {
       margin-bottom: 45px;
       display: block;
       margin: 45px auto;
       @include media-breakpoint-up(md){
        display: inline;
            margin: 0;
            margin-right: 45px;
            &:last-child {
                margin-right: 0;
           }
       }
    }
}

.grecaptcha-badge { visibility: hidden; }
.mentions-recaptcha {
    font-size: .75rem;
    color: #fff;
    padding: 0 0 8px 0;

    @include media-breakpoint-down(sm){
        font-size: .65rem;
    }

    a {
        color: #fff;
    }
}

#footer {
    background-color: #000;
    padding: 50px 80px;
}

#reseaux {
    margin-bottom: 35px;
    min-width: 80px;
    a {
        display: inline-block;
        width: 30px;
        height: 30px;
    }
    .facebook {
        margin-right: 10px;
    }
}

#adresse {
    color: $white;
    font-size: 1.4rem;
    font-weight: 500;
    line-height: 1;
    text-transform: uppercase;
    address {
        margin-bottom: 0;
        br {
            display: none;
        }
        @include media-breakpoint-down(sm){
            br {
                display: block;
            }
        }
    }

}