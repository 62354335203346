
html { 
	overflow-x: hidden;
	overflow-y: scroll;
	margin: 0; padding: 0;
	width: 100%;
    min-height: 100vh;
}

body {
	margin: 0; padding: 0;
	-webkit-text-size-adjust: 100%;
	overflow-x: hidden;
	width: 100%;
	padding-right: 0 !important; // Pour contrer les 17px des modales boostrap
	letter-spacing: 0.01rem; // BASE spacing
    color: white;
    min-height: 100vh;
}

img {
    display: block;
    max-width: 100%;
}

p {
    margin: 0;

    &.small {
        font-size: 1.4rem;
    }

    &.big {
        font-size: 1.7rem;
    }
}

* {
    font-family: 'Khand', sans-serif;
}

.bouton_type_1 {
    color: white !important;
    background-color: black;
    padding: 6px 30px;
    border-radius: 1000px;
    text-decoration: none;
    font-size: 1.4rem;
    font-weight: 400;
    white-space: nowrap;
}

.background {
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    object-fit: cover;
    width: 100%;
    height: 100%;
}

.bloc_logo {
    position: absolute;
    z-index: 10;
    top: 10px;
    left: 50%;
    transform: translateX(-50%);
    padding: 20px;
    background-color: white;

    @include media-breakpoint-down(lg) {
        top: 0;
        right: 0;
        left: initial;
        transform: initial;
    }

    .logo {
        margin: 0;
    }

    img {
        width: 120px;

        @include media-breakpoint-down(lg) {
            width: 80px;
        }
    }
}

.main_container {
    position: relative;

    .bienetre {
        position: absolute;
        bottom: 20%;
        left: 50%;
        transform: translateX(-50%);
        width: 40vw;
        max-width: 600px;

        @include media-breakpoint-down(xl) {
            max-width: 300px;
        }

        @include media-breakpoint-down(lg) {
            width: 50vw;
            max-width: 400px;
            bottom: initial;
            top: 50%;
            transform: translateX(-50%) translateY(-50%);
        }
    }

    .col_gauche, .col_droite {
        position: relative;
        padding: 8vw;
        display: flex;
        flex-direction: column;

        @include media-breakpoint-down(lg) {
            padding: 100px 80px;
        }

        .angle {
            position: absolute;
            top: 4vw;
            height: 4px;
            width: 4px;

            .haut {
                position: absolute;
                top: 0;
                height: 4px;
                width: 20vw;
                min-width: 200px;
            }

            .cote {
                width: 4px;
                height: 20vw;
                min-height: 200px;
            }
        }
    }

    .entete_logo {
        display: flex;
        align-items: flex-end;
        margin-bottom: 50px;

        > *:first-child {
            margin-right: 60px;

            @include media-breakpoint-down(lg) {
                margin-right: 30px;
            }
        }

        .logo_link {
            img {
                min-width: 100px;
            }
        }
    }

    .col_gauche {
        .angle {
            left: 4vw;
            background-color: #54C0E8;
            * { background-color: #54C0E8; }
            .haut { left: 0; }

            @include media-breakpoint-down(lg) {
                top: 30px;
                left: 30px;
            }
        }

        .textes {
            margin-bottom: 40px;
        }

        .titre {
            font-size: calc(1.5rem + 3vw);
            font-weight: 700;
            line-height: 0.8;
            margin-bottom: 0;
        }

        .bas_sp_piscine {
            width: 100%;
            max-width: 300px;
        }
    }

    .col_droite {
        text-align: right;
        align-items: flex-end;

        .angle {
            right: 4vw;
            background-color: #C4AA0D;
            * { background-color: #C4AA0D; }
            .haut { right: 0; }

            @include media-breakpoint-down(lg) {
                top: initial;
                left: initial;
                right: 30px;
                bottom: 30px;

                .cote {
                    position: absolute;
                    right: 0;
                    bottom: 0;
                }
            }
        }

        .surtitre {
            font-size: calc(0.5rem + 1.5vw);

            @include media-breakpoint-down(lg) {
                font-size: 1.6rem;
            }
        }

        .titre {
            font-size: calc(1.8rem + 4vw);
            font-weight: 700;
            line-height: 0.8;
            margin-bottom: 15px;

            @include media-breakpoint-down(lg) {
                font-size: 4.5rem;
            }

            span {
                color: #C4AA0D;
            }
        }

        p {
            line-height: 1;
            margin-bottom: 3px;
        }
    }
}

footer {
    align-items: stretch;

    img {
        width: 70px;

        @include media-breakpoint-down(lg) {
            margin-bottom: 20px;
        }
    }

    .barre {
        width: 4px;
        background-color: white;
        margin: 0 16px;
    }

    #adresse {
        * {
            text-align: left;
        }
        address {
            margin-bottom: 6px;
        }
    }
}